import React, { useMemo, useState } from "react";
import { BASE_COLUMN_TYPES, ColumnDataMap } from "../config/types";
import { CellProps } from "./Cell";
import { colors, Csku, NoteIcon } from "@commonsku/styles";
// import ReactDOM from "react-dom";
// import ProjectSidepanel from "../../../project/ProjectSidepanel";
// import { document } from "../../../../global";

// const root =
//   document.getElementById("root") || document.getElementById("resku");

type TColumn = typeof BASE_COLUMN_TYPES.Notes;
const NotesCell = ({
  column,
  row,
  className,
  style = {},
}: CellProps<TColumn>) => {
  // const [projectSidePanelOpen, setProjectSidePanelOpen] =
  //   useState<boolean>(false);
  const { accessorKey, dataTransform } = column;
  const { withNotes, job_id, job_number, href } = useMemo<
    ColumnDataMap<TColumn>
  >(() => {
    if (!dataTransform) {
      throw new Error("NoteCell requires dataTransform");
    }

    return { ...dataTransform(row.original[accessorKey]) };
  }, [accessorKey, dataTransform, row.original]);

  return (
    <>
      {/* task and notes in project sidepanel is not finished yet */}
      {/*{projectSidePanelOpen &&*/}
      {/*  ReactDOM.createPortal(*/}
      {/*    <ProjectSidepanel*/}
      {/*      projectId={job_id}*/}
      {/*      onClose={() => setProjectSidePanelOpen(false)}*/}
      {/*    />,*/}
      {/*    root,*/}
      {/*  )}*/}
      <a
        className={className}
        style={{ cursor: "pointer", ...style }}
        // onClick={() => setProjectSidePanelOpen(true)}
        href={href}
        target="_blank"
      >
        <NoteIcon
          color={withNotes ? colors.primary1["70"] : colors.primary1["20"]}
        />
      </a>
    </>
  );
};

export default NotesCell;
